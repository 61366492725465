import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutUsView.vue')
    },
    {
        path: '/copyright',
        name: 'copyright',
        component: () => import('../views/CopyrightPage.vue')
    },
    {
        path: '/termsofservice',
        name: 'terms_of_service',
        component: () => import('../views/TermsOfService.vue')
    },
    {
        path: '/privacypolicy',
        name: 'privacy_policy',
        component: () => import('../components/BacaStudiosPrivacyPolicy.vue')
    },
    {
        path: '/starrace',
        name: 'starrace',
        component: () => import('../views/StarRaceView.vue')
    },
    {
        path: '/starrace/privacypolicy',
        name: 'starrace_privacypolicy',
        component: () => import('../components/StarRacePrivacyPolicy.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

export default router;
