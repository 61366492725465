import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';

Vue.config.productionTip = false;

Vue.use(VueI18n);

const messages = {
    en: {
        privacy_policy: 'Privacy Policy'
    },
    ja: {
        privacy_policy: 'こんにちは、世界'
    }
};

// TODO: get messages and locale
// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'en', // set locale
    messages // set locale messages
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
