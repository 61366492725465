
import Vue from 'vue';
import StarRaceCard from '../components/StarRaceCard.vue';

export default Vue.extend({
    name: 'HomeView',

    components: {
        StarRaceCard
    }
});
