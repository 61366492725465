
import Vue from 'vue';
import Component from 'vue-class-component';
import Footer from './components/FooterComponent.vue';

@Component({
    components: {
        Footer
    }
})
export default class App extends Vue {
    private drawer = false;
    private cookiesSnackbarVisible = false;

    cookiesAccepted() {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted') === 'true';
        console.log('cookiesAccepted', cookiesAccepted);
        return cookiesAccepted;
    }
    acceptCookies() {
        localStorage.setItem('cookiesAccepted', 'true');
        this.cookiesSnackbarVisible = false;
    }
    mounted() {
        setTimeout(() => {
            if (!this.cookiesAccepted()) {
                this.cookiesSnackbarVisible = true;
            }
        }, 10000);
    }
    navigateTo(pageName: string) {
        if (this.$route.name !== pageName) {
            this.$router.push({ name: pageName });
        }
    }
}
